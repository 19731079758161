html {
  height: 100%;
}

body {
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#ie-wrapper {
  display: flex;
}

#root {
  /* expand to full height and leave space for footer */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
